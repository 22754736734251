import Mermaid from '../mermaid';


function MermaidGraph({data}) {
    // useEffect(() => {
    //     console.log(data);
    // }, [data]);
    return (
        <Mermaid chart={data} />
    )
}

export default MermaidGraph;