import React, { useEffect } from 'react';
import mermaid from 'mermaid';
// const PUBLIC_URL = process.env.PUBLIC_URL || '';

const Mermaid = ({chart}) => {
  useEffect(() => {
      mermaid.initialize({ startOnLoad: true });
  }, []);

  useEffect(() => {
    if (document.getElementById("mermaid-chart")) {
      document.getElementById("mermaid-chart").removeAttribute("data-processed");
      mermaid.contentLoaded();
    }
  }, [chart]);

  return (
    <div id="mermaid-chart" className="mermaid">
      {chart}
    </div>
  );
};

export default Mermaid;