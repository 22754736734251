//// Imports ////
// React Imports
import { memo, useState } from 'react';
// Material UI Imports
import { Stack, Button } from '@mui/material';
import Box from '@mui/material/Box';
// Custom Imports
import axios from 'axios';
import IfsCard from '../components/IFSGenerator/IfsCard';
import IfsModal from '../components/IFSGenerator/IfsModal';
import config from '../config';
const { BACKEND_ENDPOINT: APIEndpoint } = config;




const IFSGenerator = () => {
    // IFS Array [{
    //    num_rules: 3,
    //    iterations: 100000,
    //    div_threshold: 10000000,
    //    canvas_size: 512,
    //    b64_image: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAA...",
    //    rules: [{
    //        hsv_color: [0, 0, 1],
    //        a: 0.00337, b, ..., p
    //    }, {...}, {...}]
    // }, {...}, {...}]
    // TODO: Delete test data
    const [ifsArray, setIfsArray] = useState([]);
    const [openedIfs, setOpenedIfs] = useState(null);
    const [assignedId, setAssignedId] = useState(0);

    const handleOpenIfs = (index) => {
        setOpenedIfs(index);
    };

    const handleCloseIfs = (new_parameters) => {
        // console.log("Close Ifs", new_parameters);
        // Todo: render new image if parameters changed
        if (new_parameters.param_changed) {
            // Set the image to null to show loading spinner
            setIfsArray(prevIfsArray => prevIfsArray.map((ifs, index) => index === openedIfs ? {
                ...new_parameters, b64_image: null, param_changed: false
            } : ifs));
            // Get the IFS image from API and put it to the Ifs Card
            axios.post(APIEndpoint + '/ifs/generate_ifs', new_parameters)
                .then(response => {
                    // Update IFS image
                    setIfsArray(prevIfsArray => prevIfsArray.map((ifs, index) => index === openedIfs ? {...ifs, b64_image: response.data.data, param_changed: false} : ifs));
                })
                .catch(error => {
                    console.log(error);
            });
        } else {
            // Parameters may have been changed, but the image is also updated
            // So just update the parameters (b64_image included), but no need to redraw the image
            setIfsArray(prevIfsArray => prevIfsArray.map((ifs, index) => index === openedIfs ? {...new_parameters} : ifs));
        }
        setOpenedIfs(null);
    };

    const handleCreateIfs = async () => {
        // Get parameters from API
        axios.get( APIEndpoint + '/ifs/get_parameters', { params: { colored: "yes" } })
            .then(response => {
                // Create new Ifs Card with the parameters (add an uid for base64 image appending)
                const this_id = assignedId + 1;
                setAssignedId(this_id);
                setIfsArray([...ifsArray, {...response.data.data, uid: this_id}]);
                // Get the IFS image from API and put it to the Ifs Card
                axios.post(APIEndpoint + '/ifs/generate_ifs', response.data.data)
                    .then(response => {
                        // Update IFS image
                        setIfsArray(prevIfsArray => prevIfsArray.map((ifs) => {
                            if (ifs.uid === this_id) return {...ifs, b64_image: response.data.data, param_changed: false};
                            return ifs;
                        }));
                    })
                    .catch(error => {
                        console.log(error);
                })
            })
            .catch(error => {
                console.log(error);
            });
    };

    const handleDeleteIfs = async (index) => {
        console.log("Delete Ifs", index);
        setIfsArray(ifsArray.filter((ifs, i) => i !== index));
        if (openedIfs === index) setOpenedIfs(null);
    };

    return (<>
        <Stack direction="row" useFlexGap flexWrap="wrap" spacing={2}>
            {ifsArray.map((ifs, index) => (
                <IfsCard key={index} ifs={ifs} index={index} handleOpenIfs={handleOpenIfs} handleDeleteIfs={handleDeleteIfs} />
            ))}
            <Box style={{ width: 200, height: 200, display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px dashed grey' }}>
                <Button onClick={handleCreateIfs} variant="contained" color="primary">Add IFS</Button>
            </Box>
        </Stack>
        {openedIfs !== null && (
            <IfsModal 
                ifs={ifsArray[openedIfs]} 
                handleCloseIfs={handleCloseIfs}
                setIfsParam={(param) => setIfsArray(prevIfsArray => prevIfsArray.map(
                    (ifs, index) => index === openedIfs ? {...ifs, ...param, param_changed: true} : ifs
                ) ) }
            />
        )}
    </>);
};

export default memo(IFSGenerator);