import Mermaid from '../mermaid';
import { useEffect } from 'react';

const data_processor = (data) => {
    let processed_data = "";
    // Basic structure
    processed_data += "xychart-beta\n";
    // X-axis info
    processed_data += "x-axis [";
    for (let i = 0; i < data.length; i++) {
        processed_data += '"' + data[i].interval + '"' + (i !== data.length-1? ", ": "");
    }
    processed_data += "]\n";
    // Y-axis info
    processed_data += "y-axis Number ";
    let max = Math.max(...data.map(d => d.counts));
    processed_data += `0 --> ${max}\n`;
    // Bar data
    processed_data += "bar [";
    for (let i = 0; i < data.length; i++) {
        processed_data += data[i].counts + (i !== data.length-1? ", ": "");
    }
    processed_data += "]\n";
    return processed_data;
}

function MermaidBar({data}) {
    useEffect(() => {
        console.log("The data:", data_processor(data));
    }, [data]);

    return (
        <Mermaid chart={data_processor(data)} />
    )
}

export default MermaidBar;