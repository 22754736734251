import { Typography, Container, Box, Paper } from '@mui/material';
import { memo } from 'react';

function About () {
  return ( 
    <Container maxWidth="md">
      <Box my={4} mt={12}>
        <Paper elevation={3} style={{ padding: '20px', backgroundColor: '#f5f5f5' }}>
          <Typography variant='h1' align='center' gutterBottom>About</Typography>
          <Typography variant='h5' align='center' paragraph>
            Why This Site?
          </Typography>
          <Typography variant='body1' align='justify' paragraph>
            This site is all about sharing my personal projects, experiments, and the fun I have tinkering with everything from frontend and backend development to data science, and some good ol' math and linguistic stuff (because why not?).
          </Typography>
          <Typography variant='body1' align='justify' paragraph>
            Projects on this site:
          </Typography>
          <Typography variant='body1' align='justify' paragraph>
            - <b>IFS (Iterative Function System) Generator</b>: This tool allows you to create fractals using mathematical transformations, providing an interactive and educational experience for those interested in the beauty of fractals and chaos theory.
          </Typography>
          <Typography variant='body1' align='justify' paragraph>
            - <b>Data Visualization</b>: Here, I experiment with various frontend packages to visualize data, making complex datasets more understandable and insightful through interactive charts and graphs.
          </Typography>
        </Paper>
      </Box>
    </Container>
  )
}

export default memo(About);