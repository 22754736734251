import { memo, useEffect, useState, useMemo } from 'react';
import { APIEndpoint } from '../App';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { ChartJsNative, ReactChartJs2, D3Native } from './bar_utils';
import { ChartJSDataMaker } from './bar_utils/dataMaker';
import MermaidBar from '../components/DataVisualization/BarCharts/MermaidBar';


const barMethodList = [
  {
    caption: 'Chart.js (native)', component: ChartJsNative,
    conclusion: "Using native Chart.js in React is workable, but 1. Chart object must be retained in React ref and 2. Ref to the canvas must be established before being passed to Chart."
  },
  {
    caption: 'react-chartjs-2', component: ReactChartJs2,
    conclusion: "With package one can focus more on data and options and less on ref checking. For efficiency it seems to need registering things (to be understood)"
  },
  {
    caption: 'D3.js (native)', component: D3Native,
    conclusion: "Under construction"
  },
  {
    caption: 'Mermaid', component: MermaidBar,
    conclusion: "Very easy to use, just put in the right data format in text and it will draw the chart. But it is not as flexible as other packages."
  }
]



function ChartWrapper () {
  // Variables for data
  const [selBook, setSelBook] = useState("");
  const [bookList, setBookList] = useState([]);
  const [bookStats, setBookStats] = useState([]);
  // Variables for method
  const [barMethod, setBarMethod] = useState(0);
  const BarComp = useMemo(()=>barMethodList[barMethod].component, [barMethod])
  const handleSelectBarMethod = (e) => {setBarMethod(e.target.value)}
  
  
  
  //// Read initial data from API
  useEffect( () => {
    axios.get( APIEndpoint+"/data_vis/available_books",
    ).then( (resp) => {
      if (!resp.data.status) {
        setBookList(resp.data.data);
        setSelBook(resp.data.data[0]);
      }
    }).catch( (err) => {
      console.log(err)
    })
  }, [])
  
  
  
  //// Select New Novel
  // Set selected book
  const handleSelectBook = (e) => setSelBook(e.target.value)
  
  // Read stats when selBook is set
  useEffect( () => {
    axios.get( APIEndpoint+"/data_vis/paragraph_lengths",
      { params: { book: selBook } }
    ).then( (resp) => {
      if (!resp.data.status) {
        // console.log("READ STATS", resp.data.data)
        setBookStats(resp.data.data);
      }
    }).catch( (err) => {
      console.log(err);
    })
  }, [selBook])
  
  // Make data for Chart.js when bookStats is read
  const chartData = useMemo(() => {
    let rtnData = null;
    switch (barMethod) {
      case 0:
      case 1:
        rtnData = ChartJSDataMaker(bookStats);
        break
      case 2:
      case 3:
        rtnData = bookStats;
        break
      default:
        console.log("Unknown method:", barMethod);
    }
    return rtnData;
  }, [bookStats, barMethod]);
  
  
  
  return (<>
    <Stack direction="row" justifyContent='space-between'>
      <Stack direction="row" spacing={2} alignItems='center'>
        <Select
          labelId="method-select" id="method-select" defaultValue={0} align='left'
          value={barMethod} onChange={handleSelectBarMethod} label="book" size='small'
        >
          { barMethodList.map( ({caption}, idx) =>
            <MenuItem value={idx} key={idx}>{caption}</MenuItem>
          ) }
        </Select>
        <Typography variant='h6' align='left'>{barMethodList[barMethod].caption}</Typography>
      </Stack>
      <Stack direction="row" justifyContent="flex-end">
        <Select
          labelId="book-select" id="book-select" defaultValue=""
          sx={{ display: 'inline-flex' }} size='small'
          value={selBook} onChange={handleSelectBook} label="book"
        >
          { bookList.map( (book_name, idx) =>
            <MenuItem value={book_name} key={idx}>{book_name}</MenuItem>
          ) }
        </Select>
      </Stack>
    </Stack>
    <BarComp data={chartData} />
    <Typography variant='body1'>{barMethodList[barMethod].conclusion}</Typography>
  </>)
}

export default memo(ChartWrapper);