// import logo from './logo.svg';
// import './App.css';
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';
// import { Auth0Provider } from '@auth0/auth0-react';
import { Auth0ProviderWithNavigate } from './components/Auth0ProviderWithNavigate.js';
import Layout from './common/Layout';
import NoPage from './common/NoPage';
import About from './common/About';
import Auth0Protected from './common/Auth0Protected';
// Projects
import IFSGenerator from './pages/IFSGenerator';
import BotFrame from './clerk_bot/BotFrame';
import MultiTranscriber from './multi_transcriber/MultiTranscriber';
import D3Collection from './d3_collection/D3Collection';
import MandelbrotSet from './mandelbrot_set/MandelbrotSet';
import TomatoTimer from './pages/TomatoTimer';
import TableLLMAgent from './pages/TableLLMAgent';
import TestScurity from './pages/TestScurity';
// Auth func
import { authCheck as TableLMIAuthCheck } from './api/table_lmi';
import { authCheck as TomatoTimerAuthCheck } from './api/tomato_timer';


// Import config (env vars)
// const { APIEndpoint } = require('./config/' + (process.env.REACT_APP_ENV ?? 'dev') + '.env.json');
import config from './config';

// export const EndpointContext = createContext();
const { BACKEND_ENDPOINT: APIEndpoint } = config;
export { APIEndpoint };



function App() {
  
  return (
    // <EndpointContext.Provider value={endpoint}>
    // <Auth0Provider
    //   domain={process.env.REACT_APP_AUTH0_DOMAIN}
    //   clientId={process.env.REACT_APP_AUTH0_CLIENTID}
    //   authorizationParams={{
    //     redirect_uri: process.env.REACT_APP_AUTH0_CALLBACK, //"window.location.origin",
    //     audience: process.env.REACT_APP_AUTH0_AUDIENCE
    //   }}
    // >
    <BrowserRouter>
      <Auth0ProviderWithNavigate>
        <Routes>
          <Route path="/" element={ <Layout /> } >
            <Route index element={ <About /> } />
            <Route path="proj" element={ <Outlet/> } >
              <Route index element={ <About/> } />
              {/* Public projects */}
              <Route path='ifs_generator' element={ <IFSGenerator/> } />
              <Route path='multi_transcriber' element={ <MultiTranscriber/> } />
              <Route path='data_vis' element={ <D3Collection/> } />
              <Route path='mandelbrot_set' element={ <MandelbrotSet/> } />
              {/* Auth0 Protected projects */}
              <Route path='llm_bot' element={ <Auth0Protected children={<BotFrame/>} /> } />
              <Route path='tomato_timer' element={ <Auth0Protected
                children={<TomatoTimer/>} auth_func={TomatoTimerAuthCheck}
              /> } />
              <Route path='table_llm_agent' element={ <Auth0Protected 
                children={<TableLLMAgent/>} auth_func={TableLMIAuthCheck}
              /> } />
            </Route>
            <Route path='test_scrt' element={ <TestScurity/> } />
            <Route path='*' element={ <NoPage/> } />
          </Route>
        </Routes>
      </Auth0ProviderWithNavigate>
    </BrowserRouter>
  );
}

export default App;
