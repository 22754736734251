import { Typography, Container } from '@mui/material';

function NoPage () {
  return (
    <Container style={{ textAlign: 'center', marginTop: '20%', backgroundColor: '#fff', padding: '2rem', borderRadius: '8px' }}>
      <Typography variant="h2" gutterBottom>
        404
      </Typography>
      <Typography variant="h5" gutterBottom>
        Oops! The page you are looking for does not exist.
      </Typography>
      <Typography variant="body1" gutterBottom>
        It might have been moved or deleted.
      </Typography>
    </Container>
  )
}

export default NoPage;