//// This is the main component for the graph chart. Its functions:
// 1. Allow users to select the data source they want to visualize.
// 2. Allow users to select different methods (i.e. data visualization packages) to visualize the data.
// 3. Fetch the data from the API
// 4. Host the data visualization components of different packages.

//// Imports
// React imports
import { useEffect, useState, useMemo } from 'react';
// Material-UI imports
import { Stack, Select, MenuItem, Typography } from '@mui/material';
// Custom imports
import MermaidGraph from './MermaidGraph';
import axios from 'axios';
import config from '../../../config';



const { BACKEND_ENDPOINT: APIEndpoint } = config;

//// Constants
const GraphMethodList = [
    {
        caption: "Mermaid", component: MermaidGraph, 
        comment: "Very easy to use, just put in the data in the right text format. But it is not as flexible and pretty as other packages."
    },
]




//// Main Component
function GraphChart () {
    const [graphData, setGraphData] = useState(null);
    const [lgFamily, setLgFamily] = useState("");
    const [lgFamilyList, setLgFamilyList] = useState([]);
    const [graphMethod, setGraphMethod] = useState(0);
    const GraphComp = useMemo(()=>GraphMethodList[graphMethod].component, [graphMethod]);
    
    const handleSelectGraphMethod = (e) => {setGraphMethod(e.target.value)};

    // Retreive the list of language families on page load
    useEffect( () => {
        axios.get(`${APIEndpoint}/data_vis/lg_families`)
        .then( res => {
            setLgFamilyList(res.data.data);
            setLgFamily(res.data.data[0]);
        })
        .catch( err => {
            console.log(err);
        })
    }, []);

    // Logic of selecting a language family
    const handleSelectLgFamily = (e) => {setLgFamily(e.target.value)};
    useEffect( () => {
        // return;
        axios.get(`${APIEndpoint}/data_vis/lg_family_graph`, {params:{family: lgFamily}})
        .then( res => {
            setGraphData(res.data.data);
        })
        .catch( err => {
            console.log(err);
        })
    }, [lgFamily]);

    return ( <>
    <Stack direction="row" justifyContent='space-between'>
        <Stack direction="row" spacing={2} alignItems='center'>
            <Select
            labelId="method-select" id="method-select" defaultValue={0} align='left'
            value={graphMethod} onChange={handleSelectGraphMethod} label="book" size='small'
            >
          { GraphMethodList.map( ({caption}, idx) =>
            <MenuItem value={idx} key={idx}>{caption}</MenuItem>
          ) }
        </Select>
        <Typography variant='h6' align='left'>{GraphMethodList[graphMethod].caption}</Typography>
      </Stack>
      <Stack direction="row" justifyContent="flex-end">
        <Select
          labelId="book-select" id="book-select" defaultValue=""
          sx={{ display: 'inline-flex' }} size='small'
          value={lgFamily} onChange={handleSelectLgFamily} label="book"
        >
          { lgFamilyList.map( (book_name, idx) =>
            <MenuItem value={book_name} key={idx}>{book_name}</MenuItem>
          ) }
        </Select>
      </Stack>
    </Stack>
        <GraphComp data={graphData} />
        <Typography variant='body1'>{GraphMethodList[graphMethod].comment}</Typography>
    </>)
}

export default GraphChart;