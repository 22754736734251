//// IfsModal component ////
// Panel for user to set/adjust IFS parameters and see the results
// Provides:
//   1. Panel on the left to set IFS parameters
//      a. Add rule button to add a new rule
//      b. Delete icon in each rule box to remove the rule
//      c. Number inputs to adjust IFS-level parameters: iterations, div_threshold, canvas_size
//      d. Number inputs in each rule box to adjust rule parameters: a, b, ..., p
//      e. Color picker in each rule box to adjust rule color
//   2. Canvas on the right to preview the generated IFS

//// Imports ////
// React imports
import { useState, useEffect } from 'react';
// Material UI imports
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, IconButton, Grid, Typography, CircularProgress, Card } from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
// Custom imports
import config from '../../config';
import axios from 'axios';
const { BACKEND_ENDPOINT: APIEndpoint } = config;

function IfsModal({ ifs, handleCloseIfs }) {
    const [parameters, setParameters] = useState(ifs);

    useEffect(() => {
        setParameters(ifs);
    }, [ifs]);

    const handleParameterChange = (key, value) => {
        const newParameters = { ...parameters };
        newParameters[key] = value;
        newParameters.param_changed = true;
        setParameters(newParameters);
    };

    const handleRuleChange = (index, key, value) => {
        const newParameters = { ...parameters };
        // Other propabilities should be adjusted
        if (key === 'p') {
            value = Math.max(0.001, value);
            const totalP = value + newParameters.rules.reduce((acc, rule, i) => i === index ? acc : acc + rule.p, 0);
            newParameters.rules = newParameters.rules.map((rule, i) => i === index ? { ...rule, p: value / totalP } : { ...rule, p: rule.p / totalP });
        } else {
            newParameters.rules[index][key] = value;
        }
        newParameters.param_changed = true;
        setParameters(newParameters);
    };

    const handleAddRule = () => {
        const newParameters = { ...parameters };
        // Use API to get a new rule
        axios.get(APIEndpoint + '/ifs/get_parameters', { params: { colored: "yes", num_rules: 2 } })
            .then(response => {
                newParameters.rules.push(response.data.data.rules[0]);
                // Normalize the probabilities
                const totalP = newParameters.rules.reduce((acc, rule) => acc + rule.p, 0);
                newParameters.rules = newParameters.rules.map(rule => ({ ...rule, p: rule.p / totalP }));
                newParameters.param_changed = true;
                setParameters(newParameters);
            })
            .catch(error => {
                console.error(error);
            });
    };

    const handleDeleteRule = (index) => {
        const newParameters = { ...parameters };
        newParameters.rules.splice(index, 1);
        // Normalize the probabilities
        const totalP = newParameters.rules.reduce((acc, rule) => acc + rule.p, 0);
        newParameters.rules = newParameters.rules.map(rule => ({ ...rule, p: rule.p / totalP }));
        newParameters.param_changed = true;
        setParameters(newParameters);
    };

    const handleRedraw = () => {
        // Redraw the IFS
        axios.post(APIEndpoint + '/ifs/generate_ifs', parameters)
            .then(response => {
                setParameters({ ...parameters, b64_image: response.data.data, param_changed: false });
            })
            .catch(error => {
                console.error(error);
            });
    }

    return (
        <Dialog open={Boolean(ifs)} onClose={() => handleCloseIfs(parameters)} maxWidth="lg" fullWidth>
            <DialogTitle>IFS Parameters</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <TextField
                                    label="Iterations"
                                    type="number"
                                    variant="standard"
                                    value={parameters.iterations}
                                    onChange={(e) => handleParameterChange('iterations', e.target.value)}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    label="Div Threshold"
                                    type="number"
                                    variant="standard"
                                    value={parameters.div_threshold}
                                    onChange={(e) => handleParameterChange('div_threshold', e.target.value)}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    label="Canvas Size"
                                    type="number"
                                    variant="standard"
                                    value={parameters.canvas_size}
                                    onChange={(e) => handleParameterChange('canvas_size', e.target.value)}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        {parameters.rules.map((rule, index) => (
                            <Card key={index} style={{ padding: 8, margin: '8px 0' }}>
                            <Grid container spacing={0} key={index} alignItems="center">
                                <Grid item xs={2}>
                                    <Typography variant="subtitle1">Rule {index + 1}</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <TextField
                                        label="p"
                                        type="number"
                                        value={rule.p}
                                        onChange={(e) => handleRuleChange(index, 'p', e.target.value)}
                                        fullWidth
                                        size="small"
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton onClick={() => handleDeleteRule(index)}>
                                        <Delete />
                                    </IconButton>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography variant="body2">X<sub>k+1</sub>=</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        label="a"
                                        type="number"
                                        value={rule.a}
                                        onChange={(e) => handleRuleChange(index, 'a', e.target.value)}
                                        fullWidth
                                        size="small"
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography variant="body2">X<sub>k</sub>+</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        label="b"
                                        type="number"
                                        value={rule.b}
                                        onChange={(e) => handleRuleChange(index, 'b', e.target.value)}
                                        fullWidth
                                        size="small"
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography variant="body2">Y<sub>k</sub>+</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        label="e"
                                        type="number"
                                        value={rule.e}
                                        onChange={(e) => handleRuleChange(index, 'e', e.target.value)}
                                        fullWidth
                                        size="small"
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography variant="body2">Y<sub>k+1</sub>=</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        label="c"
                                        type="number"
                                        value={rule.c}
                                        onChange={(e) => handleRuleChange(index, 'c', e.target.value)}
                                        fullWidth
                                        size="small"
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography variant="body2">X<sub>k</sub>+</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        label="d"
                                        type="number"
                                        value={rule.d}
                                        onChange={(e) => handleRuleChange(index, 'd', e.target.value)}
                                        fullWidth
                                        size="small"
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography variant="body2">Y<sub>k</sub>+</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        label="f"
                                        type="number"
                                        value={rule.f}
                                        onChange={(e) => handleRuleChange(index, 'f', e.target.value)}
                                        fullWidth
                                        size="small"
                                    />
                                </Grid>
                            </Grid></Card>
                        ))}
                        <Button onClick={handleAddRule} startIcon={<Add />}>
                            Add Rule
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {parameters.b64_image ? (
                            <img src={`${parameters.b64_image}`} alt="IFS Preview" style={{ width: '100%' }} />
                        ) : (
                            <CircularProgress />
                        )}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleCloseIfs(parameters)} color="primary">
                    Close
                </Button>
                <Button onClick={handleRedraw} color="primary">
                    Redraw
                </Button>
                <Button onClick={() => { /* Download parameters logic here */ }} color="primary">
                    Download Parameters
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default IfsModal;
