//// IfsCard component ////
// Entry point to each IFS on the main frame
// Provides:
//   1. Preview of the generated IFS. If the IFS is not generated, it will show a loading spinner.
//   2. Click to open the full IFS working modal.
//   3. A delete button to remove the IFS from the main frame.

//// Imports ////
// Material UI Imports
import { Card, CardActionArea, CardActions, CardMedia, Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
// Custom Imports



function IfsCard ({ ifs, handleOpenIfs, handleDeleteIfs, index }) {
    return (
        <Card style={{ width: 200, height: 200, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <CardActionArea onClick={() => handleOpenIfs(index)} style={{ flexGrow: 1 }}>
                { ifs.b64_image ? (
                    <CardMedia
                        component="img"
                        alt="IFS Preview"
                        height="140"
                        image={ifs.b64_image}
                        title="IFS Preview"
                        style={{ objectFit: 'contain' }}
                    />
                ) : (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <CircularProgress />
                    </div>
                )}
                { // Later, for auto-captioning feature
                //<CardContent>
                    // <Typography gutterBottom variant="h5" component="div">
                        // IFS {ifs.index}
                    // </Typography>
                // </CardContent>
                }
            </CardActionArea>
            <CardActions>
                <Button size="small" color="primary" onClick={() => handleDeleteIfs(index)} style={{ fontSize: '0.75rem' }}>
                    Delete
                </Button>
            </CardActions>
        </Card>
    );
}

export default IfsCard;